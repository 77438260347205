import React from "react"
import cn from "classnames"
import s from "./StarRating.module.scss"
import Icon, { IconType } from "src/components/icon/Icon"
import { useSettings } from "../../layout/page/settingsContext"
import { languageMap } from "../../sanity/languages"

interface StarRatingProps {
    rating: number
    className?: string
}

function formatRating(value:number, locale = "en-EN")  {
    return new Intl.NumberFormat(locale,{ minimumSignificantDigits: 1, maximumSignificantDigits: 2 }).format(value)
}

export default function StarRating({ rating, className }: StarRatingProps): JSX.Element {
    const { lang } = useSettings()
    const language = languageMap[lang]
    return (
        <div className={cn(s["star-rating"], className)}>
            <div className={s["star-rating__stars"]}>
                {new Array(5).fill(null).map((j,idx) => {
                    return  <Icon key={idx} className={cn(s["star-rating__star"], [s["star-rating__star--empty"]])}   type={IconType.STAR_RATING}/>
                })}
                <div className={s["star-rating__stars--filled"]} style={{ width:`${(rating/5)*100}%` }}>

                    {new Array(5).fill(null).map((j,idx) => {
                        return  <Icon key={idx} className={cn(s["star-rating__star"], [s["star-rating__star--filled"]])}   type={IconType.STAR_RATING}/>
                    })}
                </div>
            </div>
            <div className={cn(s["star-rating__number"], "paragraph-100")}>{formatRating(rating, language.intlLocale)}</div>
        </div>
    )
}
